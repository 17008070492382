import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';

export default () => (
  <Layout>
    <SEO title="Home" />
    <h1>This site is under construction!</h1>
    <p>Come back soon!</p>
  </Layout>
);
